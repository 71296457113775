import React from 'react';
import ReactDOM from 'react-dom';

import 'react-bootstrap'
import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { SiteContextProvider } from './Context/SiteContext' 

ReactDOM.render(
  <React.StrictMode>
    <SiteContextProvider>
      <App />
    </SiteContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
