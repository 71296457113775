import React, { useState,  useEffect } from 'react'
import { Link } from 'react-router-dom'

const Header = () => {

    const [loading, setLoading ] = useState(true)

    useEffect(() => {
        setTimeout ( ()=>{
            setLoading(false)
        }, 1000 ) 
    },[])

    if(loading) { return <h1 className="container text-center p-4"><i className="fas fa-spinner fa-spin"></i></h1> }

    return (
        
        <header className='container'>
            <div className='row justify-center'>
                <div className='col-md-12 text-center'>
                    <Link to={'/'}>
                        <img src="./images/header/las-alondras-carilo.png" className='w-75 nimated fast fadeIn' alt="Las Alondras Carilo - Logo" />
                    </Link>
                </div>
                <div className='col-4'>
                    <img src="./images/header/sponsor-header.png" className='d-none d-lg-block w-100' alt="Sponsor Secundario 1" />
                    <img src="./images/header/sponsor-header-v.png" className='d-lg-none w-100' alt="Sponsor Secundario 1" />
                </div>
                <div className='col-4'>
                    <img src="./images/header/sponsor-header.png" className='d-none d-lg-block w-100' alt="Sponsor Principal" />
                    <img src="./images/header/sponsor-header-v.png" className='d-lg-none w-100' alt="Sponsor Principal" />
                </div>
                <div className='col-4'>
                    <img src="./images/header/sponsor-header.png" className='d-none d-lg-block w-100' alt="Sponsor Secundario 2" />
                    <img src="./images/header/sponsor-header-v.png" className='d-lg-none w-100' alt="Sponsor Secundario 2" />
                </div>
            </div>
            
        </header>
       
    )
}

export default Header
