import React, { useState, useEffect } from 'react'
import Loading from '../Loading/Loading'
import Banner from '../Banner/Banner'

import '../../Styles/main.css'
import './Home.css'
import CarouselContainer from '../Commons/Carousel/Carousel'


const Home = () => {    

    const [loading, setLoading ] = useState(true)

    useEffect(() => {
        setTimeout ( ()=>{
            setLoading(false)
        }, 1000 ) 
    },[])

    if(loading) { return <Loading /> }

    return ( 
        <main >
          
            <CarouselContainer />
            
            <section className='container'>
                <div className='row bannerXtres__container'>

                    <div className='bannerXtres__banner bannerXtres__banner--izq'>
                        <Banner 
                        className=''
                        src={'./images/home/banner-gastronomia.png' }
                        link= {'/gastronomia/'} 
                        target={''}
                        section='home'
                        descr={'Banner de oferta gastronómica.'} />
                    </div>

                    <div className='bannerXtres__banner bannerXtres__banner--cen'>
                        <Banner 
                        className=''
                        src={'./images/home/banner-locales.jpg'} 
                        link= {'/locales/'}
                        target={''}
                        section='home'
                        descr={'Locales comerciales que encontraran en el Paseo.'} />
                    </div>

                    <div className='bannerXtres__banner bannerXtres__banner--der'>
                        <Banner 
                        className=''
                        src={'./images/home/banner-promos.jpg'} 
                        link= {'/locales/'}
                        target={''}
                        section='home'
                        descr={'Promociones disponibles en los Locales.'} />
                    </div>

                </div> 

                <div className='row bannerXdos__container'>

                    <div className='bannerXdos__banner bannerXdos__banner--izq'>
                        <Banner 
                        className=''
                        src={'./images/home/banner-donde-estamos.jpg' }
                        link= {'/donde-estamos/'}
                        target={''}
                        section='home' 
                        descr={'¿Dónde estamos?'} />
                    </div>

                    <div className='bannerXdos__medium'>
                        <img src='./images/home/banner-separador.jpg' className='d-block w-100 bannerXdos__medium--image' alt="Grafica de Las Alondras Carilo" />
                    </div>

                    <div className='bannerXdos__banner bannerXdos__banner--der'>
                        <Banner 
                        className=''
                        src={'./images/home/banner-quienes-somos.jpg'} 
                        link= {'/quienes-somos/'}
                        target={''}
                        section='home' 
                        descr={'Quienes Somos.'} />
                    </div>
                </div> 
            </section> 
      </main>
    )
}

export default Home
