import React, {useContext, useState} from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'

import SiteContext from '../../../Context/SiteContext'

const Footer = ( props ) => {

    const divSection = props.divSection;
    const {setFooter} = useContext(SiteContext);
    console.log('footer: ', setFooter())

    return (

        <footer className='container'>
            
            { divSection !== 'interna' ? 
                
                <div className='row footer__container pt-5 pb-5'>
                    <div className='footer__columna'>
                        <div>
                            <p className='footerTextos footerTextos__izq'> 
                                INSTAGRAM 
                                <img src="./images/navbar-instagram.jpg" className="navbar__icons--image" alt="Cuenta de las Alondras en Instagram"></img>
                            </p> 
                            <p className='footerTextos  footerTextos__izq'> 
                                info@lasalondrascarilo.com.ar 
                                <img src="./images/navbar-mail.jpg" className="navbar__icons--image" alt="Cuenta de email las Alondras"></img>
                            </p> 
                            <p className='footerTextos footerTextos__izq'> 
                                0229 345 6578 
                                <img src="./images/icono-telefono.jpg" className="navbar__icons--image" alt="Numero telefonico."></img>
                            </p>   
                        </div>
                    </div>

                    <div className='footer__separador footer__separador'>
                        <img src='./images/footer-separador.jpg' className='d-block w-100' alt="Grafica de Las Alondras Carilo" />
                    </div>

                    <div className='footer__columna'>
                        <div>
                            <p className='footerTextos footerTextos__der'> 
                                <img src="./images/icono-facebook.jpg" className="navbar__icons--image" alt="Cuenta de las Alondras en Facebook"></img>
                                facebook 
                            </p> 
                            <p className='footerTextos footerTextos__der'> 
                                <img src="./images/icono-direccion.jpg" className="navbar__icons--image" alt="Ubicacion de Las Alondras"></img>    
                                Divisadero entre cerezo y avellano, cariló.
                            </p> 
                            <p className='footerTextos footerTextos__der'> 
                                <img src="./images/icono-horarios.jpg" className="navbar__icons--image" alt="Horarios en que estamos abierto.s"></img>
                                Todos los dias de 10 a 24 hrs
                            </p>   
                        </div>
                    </div>
                </div>  
                : '' 
            }

            <div className='row pb-5'>
                <div className="footerItem col-6 col-sm-6 col-md-3 mb-4">
                    <Link to={'/videos-playa/'}>
                        <div className='footerItem__backgroundHover'>
                            <h4>VIDEOS EN LA PLAYA</h4>
                        </div>
                        <img src="./images/home/thumb-video-playa.jpg" className="d-block w-100" alt="Horarios en que estamos abierto.s"></img>
                    </Link>
                </div>
                <div className="footerItem col-6 col-sm-6 col-md-3 mb-4">
                    <Link to={'/videos-bosque/'}>
                    <div className='footerItem__backgroundHover'>
                            <h4>VIDEOS del BOSQUE</h4>
                        </div>
                        <img src="./images/home/thumb-video-bosque.jpg" className="d-block w-100" alt="Horarios en que estamos abierto.s"></img> 
                    </Link>
                </div>
                <div className="footerItem col-6 col-sm-6 col-md-3">
                    <Link to={'/imagenes-locales/'}>
                    <div className='footerItem__backgroundHover'>
                            <h4>IMAGENES DE LOS LOCALES</h4>
                        </div>
                        <img src="./images/home/thumb-locales.jpg" className="d-block w-100" alt="Horarios en que estamos abierto.s"></img> 
                    </Link>
                </div>
                
                <div className="footerItem col-6 col-sm-6 col-md-3">
                    <Link to={'/imagenes-paseando/'}>
                    <div className='footerItem__backgroundHover'>
                            <h4>VIDEOS DEL PASEO</h4>
                        </div>
                        <img src="./images/home/thumb-paseando.jpg" className="d-block w-100" alt="Horarios en que estamos abierto.s"></img> 
                    </Link>
                </div>
            </div>

      </footer>

    )
}

export default Footer
