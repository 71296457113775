import React from 'react'

const Loading = () => {
    return (
        <div className='container text-center p-5'>
        <p className='loadingText'>Cargando</p>    
          <i className="fas fa-spinner fa-spin fa-3x"></i>
        </div>
    )
}

export default Loading
