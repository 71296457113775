import React from 'react'
import { Carousel } from 'react-bootstrap'

const CarouselContainer = () => {
  return (
    <section>
      <Carousel className='carousel__box' >
        <Carousel.Item>
            <img
            className="d-block w-100 d-none d-lg-block"
            src="./images/home/carousel-image-1.jpg"
            alt="Paseo de compras Las Alondras Carilo"
            loading="lazy"
            />
            <img
            className="d-block w-100 d-lg-none"
            src="./images/home/carousel-image-1m.jpg"
            alt="Paseo de compras Las Alondras Carilo"
            loading="lazy"
            />
            
        </Carousel.Item>

        <Carousel.Item >
            <img
            className="d-block w-100 d-none d-lg-block"
            src="./images/home/carousel-image-2.jpg"
            alt="Paseo de compras Las Alondras Carilo"
            loading="lazy"
            />
            <img
            className="d-block w-100 d-lg-none"
            src="./images/home/carousel-image-2m.jpg"
            alt="Paseo de compras Las Alondras Carilo"
            loading="lazy"
            />

        </Carousel.Item>

        <Carousel.Item >
            <img
            className="d-block w-100 d-none d-lg-block"
            src="./images/home/carousel-image-3.jpg"
            alt="Paseo de compras Las Alondras Carilo"
            loading="lazy"
            />

            <img
            className="d-block w-100 d-lg-none"
            src="./images/home/carousel-image-3m.jpg"
            alt="Paseo de compras Las Alondras Carilo"
            loading="lazy"
            />
           
        </Carousel.Item>

        </Carousel>
    </section>
  )
}

export default CarouselContainer