import { createContext, useState } from "react";

// REFERENCE of the Context
export const SiteContext = createContext();

// EXPORT nombed
export const SiteContextProvider = ({ children }) => {

    // const [footerValue, getFooter ] = useState('home');
    const setFooter = ( valueFooter ) => {
        console.log('setFooter()');
        return valueFooter
    }

    return (

        <SiteContext.Provider
            value={{
                setFooter,
            }}
            >
            {children}
        </SiteContext.Provider>

    )
}

export default SiteContext