import React from 'react'
import './Banner.css'
import { Link } from 'react-router-dom'

const Banner = ( props ) => {

    const sinLink = props.link;
    const enSection = props.section;
    console.log('sinLink: ', sinLink);

    return (
        
        <div className = { 
                `text-center 
                ${ sinLink !== 'sinLink' ? 'bannerContainer' : '' } 
                ${ enSection !== 'home' ? 'locales__banner' : '' }` }
                style={{marginBottom : '30px'}}>

            { sinLink !== 'sinLink' 

                ?   <Link to={ `${ props.link }` } target={ props.target } >
                        <img src={ props.src } className='d-block w-100' alt={ props.descr } loading='lazy'/>
                    </Link>

                :   <img src={ props.src } className='d-block w-100' alt={ props.descr } loading='lazy'/>

            }
        </div>
    )
}

export default Banner
