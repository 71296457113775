import React, { useState, useEffect } from 'react'
import Loading from '../Loading/Loading'
import Banner from '../Banner/Banner'
import '../../Styles/main.css'

const Locales = () => {

    const [loading, setLoading ] = useState(true)

    useEffect(() => {
        setTimeout ( ()=>{
            setLoading(false)
        }, 1000 ) 
    },[])

    if(loading) { return <Loading /> }


    return (
        <section>
            <div className='container-fluid p-0'>
                <img src="./images/locales/header_locales.jpg" className='d-block w-100' alt="Locales en Las Alondras Carilo" />            
            </div>
            <div className='container'>

                <h1 className='quienesSomos__titulo'>
                    <img src="./images/quienes-somos/logo-200x200.jpg" className='d-block' alt='Las Alondras Cariló' />
                    LOCALES
                </h1>
          
                <div className='row'>
                    <div className='col-md-12'>
                        <p className='quienesSomos__texto'>
                            En Las Alondras Cariló vas a encontrar las mejores marcas en diversos rubros, aprovechá las promos vigentes con
                            tus tarjetas de débito y credito o las promociones de cada local en efectivo.<br />
                        </p>
                    </div>
                </div>
                <div className='row justify-content-center p-5'>
                    <div className='col-6 col-sm-6 col-md-3 col-lg-2'>
                        <Banner 
                            className=''
                            src={'./images/locales/01-cook.jpg' }
                            link= {'sinLink'}
                            target={''}
                            section='locales'
                            descr={'Cook'} />
                    </div>
                    
                    <div className='col-6 col-sm-6 col-md-3 col-lg-2'>
                        <Banner 
                                className=''
                                src={'./images/locales/02-elepants.jpg' }
                                link= {'sinLink'}
                                target={'_blank'}
                                section='locales'
                                descr={'Elepants'} />
                    </div>

                    <div className='col-6 col-sm-6 col-md-3 col-lg-2'>
                        <Banner 
                                className=''
                                src={'./images/locales/03-emporio.jpg' }
                                link= {'sinLink'}
                                target={'_blank'}
                                section='locales'
                                descr={'Emporio'} />
                    </div>

                    <div className='col-6 col-sm-6 col-md-3 col-lg-2'>
                        <Banner 
                                className=''
                                src={'./images/locales/04-levis.jpg' }
                                link= {'sinLink'}
                                target={'_blank'}
                                section='locales'
                                descr={'Levis'} />
                    </div>

                    <div className='col-6 col-sm-6 col-md-3 col-lg-2'>
                        <Banner 
                                className=''
                                src={'./images/locales/05-la-almeja.jpg' }
                                link= {'sinLink'}
                                target={'_blank'}
                                section='locales'
                                descr={'La Almeja'} />
                    </div>

                    <div className='col-6 col-sm-6 col-md-3 col-lg-2'>
                        <Banner 
                                className=''
                                src={'./images/locales/06-mc-bar.jpg' }
                                link= {'sinLink'}
                                target={'_blank'}
                                section='locales'
                                descr={'Mc Bar Caffe & Waffles'} />
                    </div>
                
                    <div className='col-6 col-sm-6 col-md-3 col-lg-2'>
                        <Banner 
                            className=''
                            src={'./images/locales/07-mistura.jpg' }
                            link= {'sinLink'}
                            target={''}
                            section='locales'
                            descr={'Mistura'} />
                    </div>
                    
                    <div className='col-6 col-sm-6 col-md-3 col-lg-2'>
                        <Banner 
                                className=''
                                src={'./images/locales/08-lovely.jpg' }
                                link= {'sinLink'}
                                target={'_blank'}
                                section='locales'
                                descr={'Lovely'} />
                    </div>

                    <div className='col-6 col-sm-6 col-md-3 col-lg-2'>
                        <Banner 
                                className=''
                                src={'./images/locales/09-bocana.jpg' }
                                link= {'sinLink'}
                                target={'_blank'}
                                section='locales'
                                descr={'Alfajores Bocana'} />
                    </div>

                    <div className='col-6 col-sm-6 col-md-3 col-lg-2'>
                        <Banner 
                                className=''
                                src={'./images/locales/10-classlife.jpg' }
                                link= {'sinLink'}
                                target={'_blank'}
                                section='locales'
                                descr={'Classlife'} />
                    </div>

                    <div className='col-6 col-sm-6 col-md-3 col-lg-2'>
                        <Banner 
                                className=''
                                src={'./images/locales/11-wanama.jpg' }
                                link= {'sinLink'}
                                target={'_blank'}
                                section='locales'
                                descr={'Wanama'} />
                    </div>

                    <div className='col-6 col-sm-6 col-md-3 col-lg-2'>
                        <Banner 
                            className=''
                            src={'./images/locales/12-colonial.jpg' }
                            link= {'https://www.instagram.com/colonialhelados'}
                            target={''}
                            section='locales'
                            descr={'Helados Colonial'} />
                    </div>

                    <div className='col-6 col-sm-6 col-md-3 col-lg-2'>
                        <Banner 
                                className=''
                                src={'./images/locales/13-peppe.jpg' }
                                link= {'https://www.instagram.com/peppenapoliok'}
                                target={'_blank'}
                                section='locales'
                                descr={'Peppe'} />
                    </div>

                    <div className='col-6 col-sm-6 col-md-3 col-lg-2'>
                        <Banner 
                                className=''
                                src={'./images/locales/14-sushi.jpg' }
                                link= {'https://www.instagram.com/sushiclubcarilo'}
                                target={'_blank'}
                                section='locales'
                                descr={'Sushiclub'} />
                    </div>

                    <div className='col-6 col-sm-6 col-md-3 col-lg-2'>
                        <Banner 
                                className=''
                                src={'./images/locales/15-tante.jpg' }
                                link= {'https://www.instagram.com/tante.restaurant/'}
                                target={'_blank'}
                                section='locales'
                                descr={'Tante restaurant y casa de té'} />
                    </div>
                </div>
            </div>

            <div className='container-fluid p-0 locales__back'>    
                <img src="./images/locales/plano-locales.jpg" className='d-block w-100 locales__img d-none d-lg-block'  alt="Plano de locales gastronomicos" />
                <img src="./images/locales/plano-locales-mobile.jpg" className='d-block w-100 locales__img d-lg-none'  alt="Plano de locales gastronomicos" />
                <div className='row quienesSomos__img--footer quienesSomos__img--footerLocales'>
                    <img  src="./images/quienes-somos/separador_bottom.jpg" alt="Separador decoración" />
                </div>
            </div>  

        </section>
    )
}

export default Locales
