import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './NavBar.css'
import './Hamburger.css'

// import { slide as Menu } from 'react-burger-menu'

const NavBar = () => {

    const [click, setClick] = useState('');

    // let menuHamburger = false;

    const showHamburger = () =>{
      
      
      if(click === '' ) {
        setClick('is-active')
        console.log('click: ', click)
      
       }
      else {

        setClick('')
        console.log('click: ', click)

      }

      // d.querySelector(panel).classList.toggle("is-active");
      // d.querySelector(panelButton).classList.toggle("is-active");
    
    }

    return (
        
        <div>

            <aside className={`panel ${click}`}>
              <nav className="menu">
                <Link onClick={ () => setClick('')} to={'/'} className='menu__items'>Home</Link>
                <span className='menu__items--lines'></span>
                <Link onClick={ () => setClick('')} to={'quienes-somos'} className='menu__items'>Quienes Somos</Link>
                <span className='menu__items--lines'></span>
                <Link onClick={ () => setClick('')}  to={'gastronomia'} className='menu__items'>Gastronomia</Link>
                <span className='menu__items--lines'></span>
                <Link onClick={ () => setClick('')} to={'locales'} className='menu__items'>Locales</Link>
                <span className='menu__items--lines'></span>
                <Link onClick={ () => setClick('')} to={'promos'} className='menu__items'>Promos</Link>
                <small className='pt-2'><i><strong>15% off</strong> pagando<br />con tarjetas de credito.</i></small>
              </nav>
            </aside>

            <div className='navbarContainer' >
              <div className='row justify-content-between navbarContainer__row'>
                  <div className='col-2'>
                    <button className="hamburger hamburger--spin js-hamburger" type="button"
                      onClick={ () => showHamburger () }
                    >
                      <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                      </span>
                    </button>
                  </div>
                  <div className='col-8 navbar__icons'>
                    <img src="./images/navbar-instagram.jpg" className='navbar__icons--image' alt="Cuenta de las Alondras en Instagram" />
                    <img src="./images/navbar-mail.jpg" className='navbar__icons--image' alt="Cuenta de las Alondras en Instagram" />
                    <img src="./images/navbar-location.jpg" className='navbar__icons--image' alt="Ubicacion de las Alondras Carilo" />
                  </div>
              </div>
            </div>
            
            
        </div>
        
    )
}

export default NavBar
