import React , {useContext, useState, useEffect } from 'react'
import Loading from '../Loading/Loading'
import CarouselContainerQuienesSomos from '../Commons/Carousel/CarouselQuieneSomos'
import '../../Styles/main.css'

import SiteContext from '../../Context/SiteContext'

const QuienesSomos = () => {

    const [loading, setLoading ] = useState(true)

    useEffect(() => {
        setTimeout ( ()=>{
            setLoading(false)
        }, 1000 ) 
    },[])

    if(loading) { return <Loading /> }

    // const {setFooter} = useContext(SiteContext);
    // setFooter('interna');
    // console.log('Quienes Somos: ', setFooter());

    return (
        <section>
             <CarouselContainerQuienesSomos />
            
             <div className='container'>
                <div>
                    <h1 className='quienesSomos__titulo'>
                        <img src="./images/quienes-somos/logo-200x200.jpg" className='d-block' alt='Las Alondras Cariló' />
                        NUESTRA HISTORIA
                    </h1>
                </div>
                
                <div className='row'>
                    <div className='col-md-6 '>
                        <p className='quienesSomos__texto'>
                            Inaugurado en 1995, Las Alondras Plaza Cariló es pionera en la zona, apostando 
                            a un formato de compras y paseo donde se podría encontrar todo en un mismo lugar. 
                            <br /><br />
                            Desde los inicios Las Alondras es elegida por las marcas para los lanzamientos  de sus productos, como automotrices, bancos, bebidas, tecnológicas, cosméticas, turismo,
                            también cabe destacar el obsequio de nuestra tradicional calco de Cariló, que año tras año sigue siendo la preferida por todos los turistas.
                            <br /><br />
                            En el año 1999 se incorporó el espacio que hoy ocupa Tante, ampliando no solo la 
                            superficie del paseo, sino también las opciones gastronómicas junto a otros restaurantes.
                        </p>
                    </div>
                    <div className='col-md-6'>
                        <p className='quienesSomos__texto'>
                            Su ubicación estratégica la ha convertido en la gran centralidad de Cariló, 
                            lo que sumado al mix comercial, gastronómico y su característica calidez, 
                            posicionaron a Las Alondras en en el paseo elegido de Cariló por los turistas
                            y las familias, en sus comienzos durante la temporada de verano,  y
                            en la actualidad, durante todo el año.<br /><br />
                            En 2015 renovó su imagen sumando nuevas marcas, nuevos locales, nuevo logo 
                            y nuevas propuestas publicitarias en el paseo para las marcas más importante 
                            del mercado.
                            <br /><br />
                            Hoy, Las Alondras Cariló sigue siendo el paseo preferído de las marcas y de la gente,
                            nos visitan más de 500.000 por temporada.
                        </p>
                    </div>
                </div>

                <div className='row quienesSomos__img--footer'>
                    <img    src="./images/quienes-somos/separador_bottom.jpg" 
                            alt="Separador decoración" 
                            />
                </div>
                <hr />
            </div>                
        </section>
    )
}

export default QuienesSomos
